import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '../components/Image';
import Icon from '../components/Icon';
import OutboundLink from '../components/OutboundLink';
import Callout from '../components/Callout';
import Quote from '../components/Quote';
import vftVolunteer from '../pdfs/SustainableFutures-VFT-VolunteerGuide-v2.pdf';
import vftEdGuide from '../pdfs/SustainableFutures-VFT-EducatorGuide-v2.pdf';
import RequestForm from '../components/RequestForm';
import MultiVideoPlayer from '../components/MultiVIdeoPlayer';
import Button from '../components/Button';

function IndexPage() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            tweetText
            emailSubject
            emailBody
          }
        }
      }
    `
  );

  const twitter = `https://twitter.com/intent/tweet?text=${site.siteMetadata.tweetText}`;

  return (
    <Layout title="Virtual Field Trip">
      {/* <Hero 
        guid={"d90c1db6-adc6-417d-b4e2-b6303f54d167"}
        mediaType={'video/mp4'}
      /> */}
      <Section className={'pt-0 pb-0 virtual-field-trip__hero'}>
        <Container>
          <Row>
            <Column className={'pt-2'}>
              <h1>Sustainable Futures Virtual Field Trip (On Demand)</h1>
            </Column>
          </Row>
          <Row>
            <Column>
              <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: 'd90c1db6-adc6-417d-b4e2-b6303f54d167',
                    poster: 'd90c1db6-adc6-417d-b4e2-b6303f54d167-xlarge.jpeg',
                  },
                  {
                    guidSrc: 'd66269bd-4e17-4ff4-9690-fccdd6e08cb9',
                    poster: 'VFT-Chapter-Thumb-1.jpg',
                  },
                  {
                    guidSrc: 'fc282047-08f6-4a32-a46b-39b1b6a40eed',
                    poster: 'VFT-Chapter-Thumb-2.jpg',
                  },
                  {
                    guidSrc: '8c60c070-20d2-4783-8a3b-e334cdbe05b1',
                    poster: 'VFT-Chapter-Thumb-3.jpg',
                  },
                  {
                    guidSrc: 'ffce5ebb-c3a3-42c7-a9c4-cfda66841295',
                    poster: 'VFT-Chapter-Thumb-4.jpg',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="virtual-field-trip">
        <Container>
          <Row>
            <Column size={10}>
              <h2 className="h1">
                Building a Successful Future with Sustainable Habits
              </h2>
              <p>
                Give students an up-close look at the countless ways sustainable
                innovation can be embraced in everyday life, like individual
                actions, exciting green career pathways, and historic global
                initiatives. This immersive virtual journey empowers students to
                pair big goals with bold action as they get involved in creating
                the future they want to see, starting in their own communities.
                Along the way, students will gain new perspectives from experts
                at Trane Technologies, a youth sustainability advocate, and
                more!
              </p>
            </Column>
            <Column size={2} className={'virtual-field-trip__tablet'}>
              <Image filename="icon-vftpg-successfuture.png" />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={7} className="virtual-field-trip__content">
              <h3>Classroom Conversation Starters </h3>
              <p>
                Dive deeper into important topics covered in the Virtual Field
                Trip, including climate change, food waste, engineering
                sustainability, and DIY solutions. Consider one or more of the
                following discussion topics:
              </p>
              <ul>
                <li>How would you define “sustainable”?</li>
                <li>
                  Can you think of an actionable goal to support sustainability?
                </li>
                <li>
                  Does food ever go to waste in your household? Where do you see
                  evidence of food loss in your community?
                </li>
                <li>
                  What individual skills could you contribute to creating
                  real-world change?
                </li>
              </ul>
              <h3 className="mt-5">Join the Conversation</h3>
              <p>
                <strong>
                  Follow us @DiscoveryEd for content updates and more.
                </strong>
              </p>
              <p>
                Connect with us and share the ways you’re using Sustainable
                Futures resources.
              </p>
              <p>
                <OutboundLink to={twitter} className="button">
                  <Icon name="twitter" marginRight />
                  #DiscoveryEducation
                </OutboundLink>
              </p>
            </Column>
            <Column mediumOffset={1} size={4}>
              <div>
                <Image filename={'img-vftpg-edguide.jpg'} />
                <div className="virtual-field-trip__card-copy">
                  <p
                    style={{ fontSize: '18px', lineHeight: '26px' }}
                    className="mb-0"
                  >
                    INNOVATION
                  </p>
                  <h3 className="mb-0 mt-0">Sustainable futures</h3>
                  <div
                    style={{ color: '#666', fontSize: '.875rem' }}
                    className="pb-0.5"
                  >
                    <Icon name="clock" marginRight />
                    <span>30 Minutes</span>
                  </div>
                  <p>
                    Extend the learning using timely classroom connectors and
                    student activities that build on concepts covered in the
                    Sustainable Futures Virtual Field Trip.
                  </p>
                  <div className="mb-1">
                    <OutboundLink to={vftEdGuide} className="button">
                      <Icon name="download" marginRight />
                      Educator Guide
                    </OutboundLink>
                    <span className="ml-1">.PDF, 987KB</span>
                  </div>
                  <div>
                    <OutboundLink to={vftVolunteer} className="button">
                      <Icon name="download" marginRight />
                      Volunteer Guide
                    </OutboundLink>
                    <span className="ml-1">.PDF, 996KB</span>
                  </div>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section
        className={'pt-0 virtual-field-trip__request'}
        id="requestavisit"
      >
        <Container>
          <Row>
            <Column size={3} className={'virtual-field-trip__mobile'}>
              <Image
                className={'virtual-field-trip__request-icon'}
                filename={'icon-vftpg-requestvisit.png'}
              />
            </Column>
            <Column medium={6} large={4}>
              <h3>Request a Classroom Visit</h3>
              <p>
                Bring real-world sustainability ideas into the classroom by
                scheduling a visit with a Trane Technologies professional,
                available for in-person and virtual learning sessions.
              </p>
              <RequestForm
                trigger={
                  <Button>
                    <Icon name="externallink" marginRight />
                    Request a visit
                  </Button>
                }
              />
            </Column>
            <Column medium={6} large={5} className={'pr-0 pl-0'}>
              <Image filename={'img-employeepg-edrequest.jpg'} />
            </Column>
          </Row>
        </Container>
      </Section>
      <Callout
        target="_blank"
        to="https://surveys.discoveryeducation.com/s3/Sustainable-Futures-Virtual-Field-Trip-Survey-short"
      >
        Did you watch the Virtual Field Trip? Let us know what you thought!
      </Callout>
      <Quote
        quote={
          'Once you see where you are in relation to your goal, you’ll have a better sense of what direction to go in next.'
        }
        img={'img-vftpg-testimonial.jpg'}
        name={'Jada'}
        title={'Virtual Field Trip Host'}
        company={''}
        className={'quote--vft mt-2'}
      />
    </Layout>
  );
}

export default IndexPage;
