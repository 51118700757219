import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classNames from 'classnames';
import Image from '../Image';
import Video from '../Video';
import './slick.min.css';
import './slick-theme.min.css';
import './styles.scss';

const defaultSettings = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 440,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const DEFAULT_THUMBNAIL = 'global/img-de-logo.png';

/**
 * A React version of the `de_vid_archive` Drupal module that uses the 'react-slick' slider library.
 */

const MultiVideoPlayer = ({ videos, settings }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderSettings = { ...defaultSettings, ...settings };

  return (
    <div className="multi-video-player">

      <div className='slider-background'>
        <div className="slider-video">
          <Video {...videos[activeIndex]} />
        </div>
      </div>

      <div className="slider-carousel">
        <Slider {...sliderSettings}>
          {videos.map((video, index) => {
            return (
              <button
                key={video.src || video.vimeo_id || video.youtube_id}
                onClick={() => setActiveIndex(index)}
                className={classNames(
                  'slide--video',
                  activeIndex === index && 'slide--current'
                )}
              >
                <div className="slide--video__thumbnail">
                  <div className="thumbnail-wrapper">
                    <Image
                      filename={
                        video.thumbnail || video.poster || DEFAULT_THUMBNAIL
                      }
                    />
                    <span className="thumbnail-play-button">&#9654;</span>
                  </div>
                </div>
                {video.title && <h2>{video.title}</h2>}
                {video.description && <p>{video.description}</p>}
              </button>
            );
          })}
        </Slider>
      </div>

    </div>
  );
};

MultiVideoPlayer.defaultProps = {
  settings: {},
};

MultiVideoPlayer.propTypes = {
  /** Override default settings. See https://react-slick.neostack.com/docs/api/ and http://kenwheeler.github.io/slick/*/
  settings: PropTypes.object,
  /** Array of video config objects. The 'description' and 'title' fields will add text to the slide thumbnail. See the Video component for the additional field details. */
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      autoplay: PropTypes.bool,
      className: PropTypes.string,
      src: PropTypes.string,
      poster: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    }).isRequired
  ),
};

export default MultiVideoPlayer;
