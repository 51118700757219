import React from 'react';
import OutboundLink from '../OutboundLink';
import { TextInput, Checkbox, Select } from '../Form';
import States from './states';

const schema = {
  educatorName: {
    label: 'Educator Name',
    component: TextInput,
    type: 'text',
    value: '',
    placeholder: '',
    required: true,
    validation: {
      test: (value) => /^[a-zA-Z0-9\s]*$/.test(value),
      message: 'Please provide an Educator Name.',
    },
  },
  schoolName: {
    label: 'School/Club Name',
    component: TextInput,
    type: 'text',
    value: '',
    placeholder: '',
    required: true,
    validation: {
      test: (value) => /^[a-zA-Z0-9\s]*$/.test(value),
      message: 'Please provide a School/Club Name.',
    },
  },
  address: {
    label: 'Address',
    component: TextInput,
    type: 'text',
    value: '',
    placeholder: '',
    required: true,
    validation: {
      test: (value) => /^[a-zA-Z0-9\s]*$/.test(value),
      message: 'Please provide an Address.',
    },
  },
  city: {
    label: 'City/Town',
    component: TextInput,
    type: 'text',
    value: '',
    placeholder: '',
    required: true,
    validation: {
      test: (value) => /^[a-zA-Z0-9\s]*$/.test(value),
      message: 'Please provide a City/Town.',
    },
  },
  state: {
    label: 'State/Province',
    component: Select,
    placeholder: 'Select',
    options: States,
    value: '',
    required: true,
    labelHidden: false,
  },
  zip: {
    label: 'ZIP Code',
    component: TextInput,
    type: 'text',
    value: '',
    placeholder: '',
    required: true,
    validation: {
      test: (value) => /^[0-9]+$/.test(value),
      message: 'Please provide a ZIP Code.',
    },
  },
  gradeLevel: {
    label: 'Grade Level of Students',
    component: TextInput,
    type: 'text',
    value: '',
    placeholder: '',
    required: true,
    validation: {
      test: (value) => /^[a-zA-Z0-9\s]*$/.test(value),
      message: 'Please provide a Grade Level of Students.',
    },
  },
  phone: {
    label: 'Phone',
    component: TextInput,
    type: 'text',
    value: '',
    placeholder: '',
    required: true,
    validation: {
      test: (value) =>
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
          value
        ),
      message: 'Please provide a Phone Number.',
    },
  },
  email: {
    label: 'Email',
    component: TextInput,
    type: 'email',
    value: '',
    placeholder: 'email@address.com',
    required: true,
    validation: {
      test: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      message: 'Please provide a valid email address.',
    },
  },
  timeWindows: {
    label:
      'Please let us know the days/time-windows that would work best for a visit.',
    component: TextInput,
    type: 'text',
    value: '',
    placeholder: '',
    required: true,
  },
  comments: {
    label: 'Additional Comments',
    component: TextInput,
    type: 'text',
    value: '',
    placeholder: '',
    required: false,
  },
  subscribeToDE: {
    label: (
      <>
        Stay up-to-date with service enhancements, new content, professional
        development opportunities, special events, education program and news
        from Discovery Education in accordance with the following{' '}
        <a
          href="https://www.discoveryeducation.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
    component: Checkbox,
    checked: false,
  },
  subscribeToPartner: {
    label: (programName, partnerPrivacyUrl) => (
      <>
        I would like to receive information about the other STEM education
        topics from {programName} in accordance with the following{' '}
        <OutboundLink to={partnerPrivacyUrl}>Privacy Policy</OutboundLink>.
      </>
    ),
    component: Checkbox,
    checked: false,
  },
};

export default schema;
